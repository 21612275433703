import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useTranslation } from '@contexts/translation'
import { Select } from "@components/form"
import MenuItem from '@material-ui/core/MenuItem'
import { useForm } from 'react-hook-form'
import { addUser } from '@api/operations';
import { getOrguser } from '@api/profile'
import { useSnackbar } from 'notistack'

function FormDialogs({ operation, getUsers, operationUsers }) {
  const { dictionary } = useTranslation()
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [orgusers, setOrgusers] = useState([]);

  const getOrgusers = async () => {
    try {
      const { data } = await getOrguser()
      setOrgusers(data.results)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getOrgusers()
  }, [])

  const {
    control,
    errors,
    getValues
  } = useForm()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async () => {
    const response = await addUser({ orguser: getValues().users, operation });
    if (response) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary['user_added'], {
          variant: 'success',
        })
        getUsers()
        handleClose()
      }

      else {
        enqueueSnackbar(dictionary['error'], {
          variant: 'error',
        })
        getUsers()
        handleClose()
      }
    }

  }
  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        type="button"
        onClick={handleClickOpen}
        sx={{ marginRight: 1 }}
      >
        {dictionary['add_users']}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dictionary['add_user_operation']}</DialogTitle>
        <DialogContent
          sx={{ width: 500 }}
        >
          <Select
            id="users"
            name="users"
            label={dictionary['select_user']}
            labelId={"users"}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.user}
            defaultValue={''}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            {(orgusers || []).map(({ email, id, role }) => {
              if (operationUsers.some(user => user.orguser === id)) {
                return
              }
              else {
                return (
                  <MenuItem key={id} value={id}>{email} - {role}</MenuItem>
                )
              }
            }
            )}
          </Select>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {dictionary['cancel']}
          </Button>
          <Button onClick={onSubmit} variant="contained"
          >
            {dictionary['send']}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialogs;
