import axios from "axios"

export async function onGetGeocodingFromAddress(address) {
    try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDXeyAen2-6wZ5yQeAvRuQCDJZCLItSAzg`);
        console.log(response)
        return response
    } catch (error) {
        console.error(error);
    }
}