import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Table, ActionTable, CellExpand, ModalEditRole } from '@components/common'
import startCase from 'lodash/startCase'
import { useTranslation } from '@contexts/translation'
import InviteModal from '@components/profile/InviteModal'
import AddUserModal from './AddUserModal'
import { useFetchData } from '@api/fetchData';
import { getAllUsers, deleteUser } from '@api/operations'
import useBreakpoints from '@hooks/useBreakpoints'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  gridContainer: {
    border: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}))

export default function OrgusersTable({ id, isSettings }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [orgusers, setOrgusers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useBreakpoints('down', 'sm')

  const [idRow, setIdRow] = useState(null)
  const [isEdit, setIsEdit] = useState(false)

  const handleEditOpen = () => {
    setIsEdit(true)
  }
  const handleEditClose = () => {
    getUsers()
    setIsEdit(false)
  }

  const { data: operationsData, isLoading: operationsIsLoading } =
    useFetchData('/operations/');

  const getUsers = async () => {
    setIsLoading(true)
    try {
      const { data } = await getAllUsers(id)
      setOrgusers(data.results)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getUsers()
  }, []);

  const actions = (id, role) => [
    // {
    // name: 'Resend',
    // onClick: async () => {

    // },  
    // },
    {
      name: dictionary['edit'],
      onClick: () => {
        handleEditOpen()
        setIdRow(id)
      },
    },
    {
      name: dictionary['delete'],
      onClick: async () => {
        await deleteUser(id)
        await getUsers()
      },
    }
  ]
  const columns = [
    {
      field: 'display',
      headerName: dictionary["email"],
      minWidth: 240,
      renderCell: CellExpand,
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 160,
      renderCell: (params) => {
        return startCase(params.row.role || '')
      },
    },
    {
      field: 'action',
      headerName: dictionary["actions"],
      flex: 1,
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => (
        <ActionTable actions={actions(params.row.id, params.row.role)} />
      ),
    }
  ]


  return (
    <div className={classes.containerTable}>
      <div className={classes.tableNav}>
        <AddUserModal operationUsers={orgusers} operation={id} getUsers={getUsers} />
        <InviteModal getUsers={getUsers} />
      </div>
      <Table
        rows={orgusers}
        columns={columns}
        columnsWidth={isSettings ? null : 800}
        loading={isLoading}
        className={classes.gridContainer}
      />
      {isEdit && (
        <ModalEditRole
          onClose={handleEditClose}
          id={idRow}
          operationId={id}
        />
      )}
    </div>

  )
}


