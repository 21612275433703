import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add';

import { onUpdateOperation } from '@api/operations'


import { useTranslation } from '@contexts/translation'
import { BankSelect, Select, StyleguidesSelect } from '@components/form'
import { ModalForm } from '@components/common'
import { BanksForm } from '@components/banks'


const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pictureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "& img": {
      width: '150px'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function OperationInvoiceSettingsForm({
  id,
  fetchUrl,
  formData,
}) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)



  const { register, handleSubmit, setError, errors, control, setValue } = useForm(
    {
      mode: 'onBlur',
      shouldUnregister: false,
    },
  )

  const bankFetchUrl = `/banks/`


  const setFormValue = () => {
    setValue('bank', formData?.bank)
    setValue('accountant_name', formData?.accountant_name)
    setValue('accountant_phone', formData?.accountant_phone)
    setValue('billing_option', formData?.billing_option)
    setValue('tax_rate', formData?.tax_rate)
    setValue('default_styleguide', formData?.default_styleguide)
  }

  useEffect(() => {
    if (formData) {
      setFormValue()
    }
  }, [formData])

  const onSubmitEdit = async (data) => {
    setLoading(true)
    const response = await onUpdateOperation(id, data)
    if (response) {
      if (response.status === 200) {
        setLoading(false)
        enqueueSnackbar(dictionary["operation_edited"], {
          variant: 'success',
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmitEdit)}
      >
        <Grid container>

          {/* Accountant  Name */}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="accountant_name"
              label={dictionary["accountant_name"]}
              name="accountant_name"
              inputRef={register}
              fullWidth
              error={errors.accountant_name}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.accountant_name && errors.accountant_name.message}
            />
          </Grid>

          {/* Accountant  Phone */}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="accountant_phone"
              label={dictionary["accountant_phone"]}
              name="accountant_phone"
              inputRef={register}
              fullWidth
              type='number'
              error={errors.accountant_phone}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.accountant_phone && errors.accountant_phone.message}
            />
          </Grid>

          {/* Bank  */}
          <Grid item xs={12}>
            <BankSelect
              control={control}
              error={errors.bank}
              errorMessage={errors.bank && errors.bank.message}
              fullWidth
              defaultValue=""
            >
              <MenuItem key={293983} value={null} onClick={() => setOpen(true)}>
                <AddIcon /> {dictionary["add_a_bank"]}
              </MenuItem>
            </BankSelect>
          </Grid>

          {/* Billing Option */}
          <Grid item xs="12">
            <Select
              id="billing_option"
              name="billing_option"
              label={dictionary["billing_option"]}
              control={control}
              defaultValue={''}
              variant="outlined"
              margin="normal"
              error={errors.billing_option}
              fullWidth
              errorMessage={errors.billing_option && errors.billing_option.message}
            >
              <MenuItem value="30_days">{dictionary["30_days"]}</MenuItem>
              <MenuItem value="45_days">{dictionary["45_days"]}</MenuItem>
              <MenuItem value="60_days">{dictionary["60_days"]}</MenuItem>
              <MenuItem value="75_days">{dictionary["75_days"]}</MenuItem>
              <MenuItem value="90_days">{dictionary["90_days"]}</MenuItem>
              <MenuItem value="end_of_month">{dictionary["end_of_month"]}</MenuItem>
              <MenuItem value="on_reception">{dictionary["on_reception"]}</MenuItem>
            </Select>
          </Grid>


          {/* Tax Rate */}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="tax_rate"
              type='number'
              label={dictionary["tax_rate"]}
              name="tax_rate"
              inputRef={register}
              fullWidth
              error={errors.tax_rate}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.tax_rate && errors.tax_rate.message}
            />
          </Grid>

          {/* Styleguides */}
          <Grid xs={12}>
            <StyleguidesSelect
              control={control}
              id={"default_styleguide"}
              error={errors.default_styleguide}
              errorMessage={errors.default_styleguide && errors.default_styleguide.message}
              fullWidth
              defaultValue={''}
            />
          </Grid>

        </Grid>
        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </form>

      {open &&
        <ModalForm type={dictionary["bank"]} onClose={() => setOpen(false)}>
          <BanksForm handleClose={() => setOpen(false)} setSelectValue={id => setValue('bank', id)} fetchUrl={bankFetchUrl} />
        </ModalForm>
      }
    </>
  )
}
