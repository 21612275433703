import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { useFetchData } from '@api/fetchData'
import useBreakpoints from '@hooks/useBreakpoints'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '600px',
    boxShadow: theme.shadows[25].z16,
    borderRadius: theme.shape.borderRadiusMd,
    [theme.breakpoints.down('md')]: {
      height: '500px !important'
    },
  },
  dashboard: {
    [theme.breakpoints.down('md')]: {
      height: '330px !important'
    },
  },
}));

GoogleMapStreetView.propTypes = {
  themes: PropTypes.object,
  className: PropTypes.string
};

function GoogleMapStreetView({ themes, className, isDashboard, center, ...other }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    version: "3.43.8",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY
  })

  const isMobile = useBreakpoints('down', 'md');


  const fetchUrl = `/operations/?is_archived=false&page_size=999`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const checkLatLong = (operations) => operations.filter(operation => operation.latitude && operation.longitude)



  const classes = useStyles();

  const mapOptions = {
    minZoom: isMobile ? 1 : 2,
    maxZoom: 20,
    scrollwheel: false,
    zoom: data && data.results && data.results.length <= 0 || data && data.results && data.results.length > 0 && checkLatLong(data.results).length <= 0 ? 1 : null,
    controlSize: 24,
    styles: [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#e9e9e9"
          },
          {
            lightness: 17
          }
        ]
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5"
          },
          {
            lightness: 20
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 17
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 29
          },
          {
            weight: 0.2
          }
        ]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 18
          }
        ]
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 16
          }
        ]
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5"
          },
          {
            lightness: 21
          }
        ]
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#dedede"
          },
          {
            lightness: 21
          }
        ]
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "on"
          },
          {
            color: "#ffffff"
          },
          {
            lightness: 16
          }
        ]
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            saturation: 36
          },
          {
            color: "#333333"
          },
          {
            lightness: 40
          }
        ]
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            color: "#f2f2f2"
          },
          {
            lightness: 19
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fefefe"
          },
          {
            lightness: 20
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#fefefe"
          },
          {
            lightness: 17
          },
          {
            weight: 1.2
          }
        ]
      }
    ]
  };


  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return isLoaded && data ? (
    <div className={clsx(isDashboard ? classes.dashboard : classes.root, className)}>
      <GoogleMap
        options={mapOptions}
        center={data && data.results && data.results.length <= 0 || data && data.results && data.results.length > 0 && checkLatLong(data.results).length <= 0 ? { lat: 0, lng: 0 } : null}
        onLoad={map => {
          if (data && data.results && data.results.length > 0 && checkLatLong(data.results).length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            data.results.map(marker => {
              if (marker.latitude !== 0 && marker.longitude !== 0) {
                bounds.extend({ lat: marker.latitude, lng: marker.longitude });
              }
            });
            map.fitBounds(bounds);
          }
        }}
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
        {...other}
      >

        {data && data.results && data.results.map(operation => {
          if (operation.latitude !== 0 && operation.longitude !== 0) {
            return (
              <Marker
                key={operation.id}
                icon={"/static/illustrations/googleMarker.png"}
                onClick={() => handleActiveMarker(operation.id)}
                position={{ lat: operation.latitude, lng: operation.longitude }}
              >
                {activeMarker === operation.id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>{operation.name}</div>
                  </InfoWindow>
                ) : null}
              </Marker>
            )
          }

        }
        )}

      </GoogleMap>
    </div>
  ) : <></>;
}

export default memo(GoogleMapStreetView);
