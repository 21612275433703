import { useState } from 'react';
import Router from 'next/router';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import { makeStyles, Button, Badge, Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab';
import {
  ActionTable,
  ModalDelete,
  ModalForm,
  Table,
  OperationFilterModal,
  RoleForm,
  ActionsMenu,
  CellExpand
} from '@components/common';
import useBreakpoints from '@hooks/useBreakpoints'
import AddIcon from '@material-ui/icons/Add'
import { onDeleteLandlord, onUpdateLandlord } from "@api/landlords";
import { useTranslation } from '@contexts/translation';
import LandlordsForm from './LandlordsForm';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useFetchData } from '@api/fetchData'


const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
    rowGap: theme.spacing(1)
  },
  tableNavOperation: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },

  tableNavFilters: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  link: {
    marginLeft: '10px',
  },
  gridContainer: {
    border: 'none',
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
  filterContainer: {
    display: 'flex'
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function LandlordsTable({ operationId, isGlobal }) {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [idRow, setIdRow] = useState(null);
  const [isArchive, setIsArchive] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [value, setValue] = useState('&is_archived=false')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setPageCount(1)
  }

  const [isFiltersModalOpened, setIsFiltersModalOpened] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({ operation: operationId });
  const [isOpenRoleForm, setIsOpenRoleForm] = useState(false)

  const operationFilter = selectedFilters.operation !== undefined ? `operations=${selectedFilters.operation}` : ''
  const fetchUrl = isGlobal ? `/landlords/?page=${pageCount}&${operationFilter}${value}` : `/landlords/?page=${pageCount}&${operationFilter}${value}`;
  const { data, isLoading, isError } = useFetchData(fetchUrl)
  const isMobile = useBreakpoints('down', 'md')

  const handleFormOpen = () => setIsOpenForm(true);
  const handleFormClose = () => setIsOpenForm(false);
  const handleEditOpen = () => setIsEdit(true);
  const handleEditClose = () => setIsEdit(false);
  const handleDeleteOpen = () => setIsDelete(true);
  const handleDeleteClose = () => setIsDelete(false);
  const handleOpenFiltersModal = () => setIsFiltersModalOpened(true);
  const handleCloseFiltersModal = () => setIsFiltersModalOpened(false);

  const handleArchiveOpen = (id) => {
    setIdRow(id)
    setIsArchive(true)
  }

  const handleArchiveClose = () => {
    setIdRow()
    setIsArchive(false)
  }


  const handleRoleFormOpen = () => {
    setIsOpenRoleForm(true)
  }

  const handleRoleFormClose = () => {
    setIsOpenRoleForm(false)
  }

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  };

  const handleFiltersApply = filters => {
    setSelectedFilters(filters);
    setPageCount(1)
    handleCloseFiltersModal();
  };

  const onDelete = async () => {
    const response = await onDeleteLandlord(idRow);

    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl);
        enqueueSnackbar(dictionary['landlord_deleted'], {
          variant: 'success',
        });
        handleDeleteClose();
      }
    }
  };


  const unarchiveLandlord = async () => {
    const data = { is_archived: false }
    const response = await onUpdateLandlord(data, idRow)
    if (response.status === 200) {
      mutate(fetchUrl)
      enqueueSnackbar(dictionary['landlord_unarchived'], {
        variant: 'success',
      })
      handleArchiveClose()
    }
  }



  const archiveLandlord = async () => {
    const data = { is_archived: true }
    const response = await onUpdateLandlord(data, idRow)
    if (response.status === 200) {
      mutate(fetchUrl)
      enqueueSnackbar(dictionary['landlord_archived'], {
        variant: 'success',
      })
      handleArchiveClose()
    }
  }


  const rowClickHandler = (event) => {
    const id = event.row.id;
    Router.push(isGlobal ? `/landlords/${id}` : `/operations/${operationId}/landlords/${id}`);
  };

  const actions = (id) => [
    {
      name: dictionary.edit,
      onClick: () => {
        handleEditOpen();
        setIdRow(id);
      },
    },
    value !== "&is_archived=true" && {
      name: dictionary['archive'],
      onClick: () => {
        handleArchiveOpen(id)
      },
    },
    value !== "&is_archived=false" && {
      name: dictionary['unarchive'],
      onClick: () => {
        handleArchiveOpen(id)
      },
    },
    {
      name: dictionary.delete,
      onClick: () => {
        handleDeleteOpen();
        setIdRow(id);
      },
    },
  ];


  const LANDLORD_TAB = [
    {
      value: '&is_archived=false',
      label: dictionary['active'],
    },
    {
      value: '&is_archived=true',
      label: dictionary['archived'],
    },
  ]


  const columns = [
    {
      field: 'display',
      headerName: dictionary['name'],
      flex: 1,
      minWidth: 380,
      renderCell: CellExpand
    },
    {
      field: 'note',
      headerName: dictionary['notes'],
      minWidth: 160,
      renderCell: CellExpand
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      type: 'number',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'action',
      headerName: dictionary.actions,
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return id && id !== 'Total' && <ActionTable actions={actions(id)} />;
      },
    },
  ];


  return (
    <div className={classes.containerTable}>
      <TabContext value={value}>
        <div className={classes.tableNav}>
          <div className={classes.filterContainer}>
            {isGlobal && <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              invisible={!selectedFilters.operation}
              color='primary'
              className={classes.filterButton}
              variant='dot'
            >
              <Button
                endIcon={<FilterListIcon />}
                color={selectedFilters.operation ? 'primary' : 'inherit'}
                onClick={handleOpenFiltersModal}
              >
                {!isMobile && dictionary['filters']}
              </Button>
            </Badge>
            }
            <TabList className={classes.tabList} onChange={handleChange}>
              {LANDLORD_TAB.map((tab) => {
                return (
                  <Tab
                    key={tab?.value}
                    label={tab?.label}
                    value={tab?.value}
                  />
                )
              })}
            </TabList>
          </div>
          <div></div>
          {isGlobal ?
            <div style={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                startIcon={<AddIcon />}
                onClick={handleFormOpen}
              >
                {!isMobile && dictionary.landlords.create_landlord}
              </Button>

            </div> :
            <div style={{ display: 'flex' }}>
              <ActionsMenu viewModeList={[dictionary.landlords.create_landlord, dictionary.landlords.add_landlord]} handleFormOpen={handleFormOpen} handleRoleFormOpen={handleRoleFormOpen} />
            </div>
          }
        </div>
      </TabContext>
      {isFiltersModalOpened && (
        <ModalForm type={dictionary['filters']} onClose={handleCloseFiltersModal}>
          <OperationFilterModal
            filterValues={selectedFilters}
            onFiltersApply={handleFiltersApply}
          />
        </ModalForm>
      )}
      {(isOpenRoleForm) && (
        <ModalForm
          className={classes.modalContainer}
          type={dictionary.landlords.landlords}
          onClose={handleRoleFormClose}
        >
          <RoleForm
            handleClose={handleRoleFormClose}
            id={idRow}
            operationId={operationId}
            location={location}
            name="landlords"
            updateItem={onUpdateLandlord}
            successKey="landlord_edited"
            onSuccess={() => mutate(fetchUrl)}
            actionLabel={dictionary.landlords.create_landlord}
            onAction={() => {
              handleRoleFormClose()
              handleFormOpen()
            }}
          />
        </ModalForm>
      )}
      <Table
        className={classes.gridContainer}
        loading={isLoading}
        rows={data && data.results ? data.results : []}
        columns={columns}
        page={pageCount}
        handlePageChange={handlePageChange}
        count={data && data.count}
        onRowClick={rowClickHandler}
      />

      {(isOpenForm || isEdit) && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary.landlords.landlord}
          onClose={isEdit ? handleEditClose : handleFormClose}
        >
          <LandlordsForm
            handleClose={isEdit ? handleEditClose : handleFormClose}
            isEdit={isEdit}
            id={idRow}
            fetchUrl={fetchUrl}
            operationId={operationId}
          />
        </ModalForm>
      )}
      {isDelete && (
        <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
      )}
      {isArchive && (
        <ModalDelete
          onClose={handleArchiveClose}
          onDelete={value !== "&is_archived=false" ? unarchiveLandlord : archiveLandlord}
          isYellow
          description={value !== "&is_archived=false" ? dictionary['confirm_unarchive_landlord'] : dictionary['confirm_archive_landlord']}
        />
      )}
    </div>
  );
}
