import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'
import { MBreadcrumbs } from '@theme/index'

import { Copyright, Layout, Loader, InfoCard } from '@components/common'
import { DashboardOperationsView } from '@components/dashboard'

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import Page404View from 'pages/404'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: 0,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  line: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  noPaddingLeft: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0 !important',
    },

  },
  img: {
    width: 280,
    boxShadow: theme.shadows[25].z8,
  },
}))

export default function OperationDetails({ operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const fetchUrl = `/operations/${operationId}/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  if (isLoading) return <Loader />
  if (isError) return <Page404View />

  const {
    photo,
    name,
    display,
    address,
    management_start_date,
    manager_name,
    accountant_name,
    surface_area_manual,
    landlords,
    type_display,
    id,
  } = data


  const usefulContacts = [
    {
      name: landlords && landlords.length > 0 && landlords[0].display,
      photo: '',
      role: 'owner',
    },
    {
      name: manager_name,
      photo: '',
      role: 'manager',
    },
    {
      name: accountant_name,
      photo: '',
      role: 'accountant',
    },
  ]

  return (
    <Layout isSidebar operationId={operationId}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MBreadcrumbs
              links={[
                { name: dictionary["home"], href: `/` },
                { name: dictionary["operations"], href: `/operations/` },
                { name: name }
              ]}
            />
          </Grid>
          <Grid className={classes.noPaddingLeft} item xs={12}>
            <InfoCard
              fetchOperation={fetchUrl}
              photo={photo}
              name={display}
              operationId={id}
              address={address}
              startDate={management_start_date}
              surface={surface_area_manual}
              typeDisplay={type_display}
              usefulContacts={usefulContacts}
            />
          </Grid>
          <DashboardOperationsView operationId={operationId} data={data} />
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
