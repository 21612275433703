import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import Router from 'next/router'
import UnformatDate from '@utils/unformatDate';
import GetFormattedDate from '@utils/formatDate';

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import { MenuItem } from '@material-ui/core'

import { onCreateOperation, onUpdateOperation } from '@api/operations'
import { onGetGeocodingFromAddress } from '@api/geocoding'

import { DatePicker, CountriesSelect, CurrenciesSelect, Select } from '@components/form'
import Upload from '@components/files/Upload';

import { v4 as uuidv4 } from 'uuid';


import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";


async function getGeocodingData(address) {
  const geocodingData = await onGetGeocodingFromAddress(address)
  const formatted_address = geocodingData?.data?.results[0]?.formatted_address
  const coordinates = geocodingData?.data?.results[0]?.geometry?.location

  return { formatted_address, coordinates }
}

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pictureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "& img": {
      width: '150px'
    }
  },
  suggestionList: {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[500_32]}`,
    padding: theme.spacing(1),
    listStyle: 'none',
    '& li:last-child': {
      borderBottom: 'none !important',
    }
  },
  suggestionItem: {
    borderBottom: `1px solid ${theme.palette.grey[500_32]}`,
    cursor: 'pointer',
    padding: theme.spacing(1, 0),
    '&:hover': { backgroundColor: theme.palette.action.hover }
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function OperationForm({
  handleClose,
  isEdit,
  id,
  fetchUrl,
  formData,
  isList
}) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [route, setRoute] = useState()
  const [streetNumber, setStreetNumber] = useState()
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null);
  const [file, setFile] = useState(null);


  const {
    ready,
    value,
    suggestions: { status, data: dataAutocomplete },
    setValue: setValueAutocomplete,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300
  });


  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });


  const [pictureUrl, setPictureUrl] = useState(null)

  const { register, handleSubmit, setError, errors, control, setValue, watch } = useForm(
    {
      mode: 'onBlur',
      shouldUnregister: false,
    },
  )

  const { data, isLoading, isError } = (isEdit && isList)
    ? useFetchData('/operations/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  const setFormValue = () => {
    setValue('name', formData?.name)
    setValue('case_number', formData?.case_number)
    setValue('street', formData?.street)
    setValue('postal_code', formData?.postal_code)
    setValue('city', formData?.city)
    setValue('unit', formData?.unit)
    setValue('country', formData?.country)
    setValue('type', formData?.type)
    setValue('parking_units_manual', formData?.parking_units_manual)
    setValue('surface_area_manual', formData?.surface_area_manual)
    setValue('currency', formData?.currency)
    setValue('manager_name', formData?.manager_name)
    setValue('manager_phone', formData?.manager_phone)
    if (formData?.management_start_date) setValue('management_start_date', UnformatDate(formData?.management_start_date))

  }

  useEffect(() => {
    if (isEdit && formData) {
      setFormValue()
      setPictureUrl(formData?.photo)
    }
    if (isEdit && isList && data) {
      setPictureUrl(data?.photo)
      setValue('name', data?.name)
      setValue('case_number', data?.case_number)
      setValue('manager_name', data?.manager_name)
      setValue('manager_phone', data?.manager_phone)
      setValue('street', data?.street)
      setValue('postal_code', data?.postal_code)
      setValue('city', data?.city)
      setValue('unit', data?.unit)
      setValue('type', data?.type)
      setValue('parking_units_manual', data?.parking_units_manual)
      setValue('country', data?.country)
      setValue('surface_area_manual', data?.surface_area_manual)
      setValue('currency', data?.currency)
      if (formData?.management_start_date) setValue('management_start_date', UnformatDate(formData?.management_start_date))
    }
  }, [formData, isEdit, data, isList])


  useEffect(() => {
    if (!route && !streetNumber) {
      return
    }
    else {
      setValue('street', `${streetNumber} ${route}`)

    }
  }, [route, streetNumber])

  const onSubmit = async (data) => {
    setLoading(true)
    const formData = new FormData(formRef.current);
    data.type && formData.append("type", data.type)
    data.country && formData.append("country", data.country)
    data.unit && formData.append("unit", data.unit)
    data.currency && formData.append("currency", data.currency)

    if (data?.street && data?.city && data?.postal_code) {
      const assembledAddress = `${data.street} ${data.postal_code} ${data.city}`
      const { formatted_address, coordinates } = await getGeocodingData(assembledAddress)
      if (coordinates) {
        formData.append("latitude", coordinates?.lat)
        formData.append("longitude", coordinates?.lng)
      }

      else {
        // setError('street', { message: dictionary['error_address'] })
        // setError('city', { message: dictionary['error_address'] })
        // setError('postal_code', { message: dictionary['error_address'] })
        return setLoading(false)
      }

    }
    if (file) {
      formData.append("photo", file.fileData)
    }
    const response = await onCreateOperation(formData)

    if (response) {
      if (response.status === 201) {
        Router.push(`/operations/${response.data.id}/`)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    const formData = new FormData(formRef.current);
    data.type && formData.append("type", data.type)
    data.country && formData.append("country", data.country)
    data.unit && formData.append("unit", data.unit)
    data.currency && formData.append("currency", data.currency)


    if (data.management_start_date) {
      formData.append('management_start_date', GetFormattedDate(data.management_start_date))
    }

    if (data?.street && data?.city && data?.postal_code) {
      const assembledAddress = `${data.street} ${data.postal_code} ${data.city}`
      const { formatted_address, coordinates } = await getGeocodingData(assembledAddress)
      if (coordinates) {
        formData.append("latitude", coordinates?.lat)
        formData.append("longitude", coordinates?.lng)
      } else {
        //setError('street', { message: dictionary['error_address'] })
        //setError('city', { message: dictionary['error_address'] })
        //setError('postal_code', { message: dictionary['error_address'] })
        return setLoading(false)
      }
    }
    if (file) {
      formData.append("photo", file.fileData)
    }
    const response = await onUpdateOperation(id, formData)

    if (response) {
      if (response.status === 200) {
        setLoading(false)
        isList && handleClose()
        enqueueSnackbar(dictionary["operation_edited"], {
          variant: 'success',
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValueAutocomplete(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValueAutocomplete(description, false);
    clearSuggestions();

    // Get details 
    getGeocode({ address: description })
      .then((results) => {
        const getStreetNumber = results[0].address_components.forEach(({ types, long_name }) => {
          if (types.includes("street_number")) setStreetNumber(long_name)
        })
        const getRoute = results[0].address_components.forEach(({ types, long_name }) => {
          if (types.includes("route")) setRoute(long_name)
        })
        results[0].address_components.forEach(({ types, long_name, short_name }) => {
          if (types.includes("postal_code")) setValue('postal_code', long_name)
          if (types.includes("locality")) setValue('city', long_name)
          if (types.includes("country")) setValue('country', short_name)

        });
      })
  };

  const renderSuggestions = () =>
    dataAutocomplete.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li className={classes.suggestionItem} key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });


  return (
    <form ref={formRef}
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="name"
            label={dictionary["name"]}
            name="name"
            inputRef={register}
            fullWidth
            error={errors.name}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.name && errors.name.message}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="case_number"
            label={dictionary["case_number"]}
            name="case_number"
            inputRef={register}
            fullWidth
            error={errors.case_number}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.case_number && errors.case_number.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            id="type"
            name="type"
            label={dictionary["type"]}
            control={control}
            defaultValue={'R'}
            variant="outlined"
            margin="normal"
            error={errors.type}
            fullWidth
            errorMessage={errors.type && errors.type.message}
          >
            <MenuItem value="R">{dictionary['residential']}</MenuItem>
            <MenuItem value="C">{dictionary['commercial']}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} ref={ref} >
          <TextField
            variant="outlined"
            margin="normal"
            id="street"
            label={dictionary["street"]}
            name="street"
            inputRef={register}
            fullWidth
            disabled={!ready}
            onChange={handleInput}
            error={errors.street}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.street && errors.street.message}
          />
          {status === "OK" && <ul className={classes.suggestionList}>{renderSuggestions()}</ul>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            id="postal_code"
            label={dictionary["postal_code"]}
            name="postal_code"
            inputRef={register}
            fullWidth
            error={errors.postal_code}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.postal_code && errors.postal_code.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            id="city"
            label={dictionary["city"]}
            name="city"
            inputRef={register}
            fullWidth
            error={errors.city}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.city && errors.city.message}
          />
        </Grid>

        <Grid item xs={12}>
          <CountriesSelect control={control} fullWidth />
        </Grid>

        <Grid item xs={8}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="surface_area_manual"
            label={dictionary["surface_area"]}
            type="number"
            id="surface_area_manual"
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.surface_area_manual}
            helperText={
              errors.surface_area_manual && errors.surface_area_manual.message
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Select
            id="unit"
            name="unit"
            label={dictionary["unit"]}
            control={control}
            defaultValue={'m'}
            variant="outlined"
            margin="normal"
            error={errors.unit}
            fullWidth
            errorMessage={errors.unit && errors.unit.message}
          >
            <MenuItem value="m">m2</MenuItem>
            <MenuItem value="ft">Ft2</MenuItem>
            <MenuItem value="unit">Units</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="parking_units_manual"
            label={dictionary["parking_unit"]}
            type="number"
            id="parking_units_manual"
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.parking_units_manual}
            helperText={
              errors.parking_units_manual && errors.parking_units_manual.message
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CurrenciesSelect
            control={control}
            fullWidth
            error={errors.currency}
            errorMessage={errors.currency && errors.currency.message}
          />
        </Grid>
        {isEdit &&
          <>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                id="manager_name"
                label={dictionary["manager_name"]}
                name="manager_name"
                inputRef={register}
                fullWidth
                error={errors.manager_name}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.manager_name && errors.manager_name.message}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                id="manager_phone"
                label={dictionary["manager_phone"]}
                name="manager_phone"
                inputRef={register}
                fullWidth
                type='number'
                error={errors.manager_phone}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.manager_phone && errors.manager_phone.message}
              />
            </Grid>
            <DatePicker
              id="management_start_date"
              name="management_start_date"
              required
              label={dictionary["management_start_date"]}
              variant="outlined"
              margin="none"
              fullWidth
              control={control}
              error={errors.management_start_date}
              errorMessage={errors.management_start_date && errors.management_start_date.message}
              clearable={true}
            />
          </>
        }
        <Grid item xs="12">
          <Upload file={file} preview={file && file.preview || pictureUrl && pictureUrl} setFile={setFile} acceptFiles={'image/jpeg, image/png'} />
        </Grid>

      </Grid>
      <Grid xs="12" className={classes.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={loading}
          startIcon={<SaveIcon />}
          className={classes.submit}
        >
          {dictionary["submit"]}
        </Button>
      </Grid>
    </form>
  )
}
