import React, { useState } from 'react'
import Link from 'next/link'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Grid, Paper, Skeleton, Typography, Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import useBreakpoints from '@hooks/useBreakpoints'
import { numberWithSpaces } from '@utils/numberWithSpaces'
import {
  ActionTable,
  CellExpand,
  Copyright,
  Layout,
  ModalDelete,
  ModalForm,
  Table,
} from '@components/common'
import OperationForm from './OperationForm'
import { FooterRow, } from '@components/operations'
import { onDeleteOperation, onUpdateOperation } from '@api/operations'
import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import Page404View from 'pages/404'
import { IndicatorCard } from '.'
import MapView from './MapView'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  picture: {
    width: 48,
    height: 48,
    marginRight: '20px',
  },
  numeric: {
    width: '100%',
    textAlign: 'right',
  },
  cardContainer: {
    margin: 0,
    width: '100%'
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  indicatorContainer: {
    display: 'flex'
  },
  tabList: {
    minHeight: 36,
  },
  tab: {
    minHeight: 36,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperCard: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%'
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
}))

export default function OperationListView() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [pageCount, setPageCount] = useState(1)
  const [isMap, setIsMap] = useState(false)

  const [value, setValue] = useState('&is_archived=false')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setPageCount(1)
    if (newValue === '&is_archived=false&') {
      return setIsMap(true)
    }
    else {
      setIsMap(false)
    }
  }

  const fetchUrl = `/operations/?page=${pageCount}${value}`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [id, setId] = useState()
  const [isDelete, setIsDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isArchive, setIsArchive] = useState(false)
  const isMobile = useBreakpoints('down', 'md')

  const deleteOperation = async () => {
    const response = await onDeleteOperation(id)
    if (response.status === 204) {
      mutate(fetchUrl)
      enqueueSnackbar(dictionary['operation_deleted'], {
        variant: 'success',
      })
      handleDeleteClose()
    }
  }

  const archiveOperation = async () => {
    const data = { is_archived: true }
    const response = await onUpdateOperation(id, data)
    if (response.status === 200) {
      mutate(fetchUrl)
      enqueueSnackbar(dictionary['operation_archived'], {
        variant: 'success',
      })
      handleArchiveClose()
    }
  }

  const handleEditOpen = () => {
    setIsEdit(true)
  }
  const handleEditClose = () => {
    setIsEdit(false)
  }

  const handleDeleteClose = () => {
    setId()
    setIsDelete(false)
  }

  const handleDeleteOpen = (id) => {
    setId(id)
    setIsDelete(true)
  }

  const handleArchiveOpen = (id) => {
    setId(id)
    setIsArchive(true)
  }

  const handleArchiveClose = () => {
    setId()
    setIsArchive(false)
  }

  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const formatUnit = (unit) => {
    if (unit === 'm') {
      return 'm2'
    } else {
      return 'ft2'
    }
  }


  const actions = (id) => [
    {
      name: dictionary['edit'],
      onClick: () => {
        handleEditOpen()
        setId(id)
      },
    },
    value !== "&is_archived=true" && {
      name: dictionary['archive'],
      onClick: () => {
        handleArchiveOpen(id)
      },
    },
    {
      name: dictionary['delete'],
      onClick: () => {
        handleDeleteOpen(id)
      },
    },
  ]

  const OPERATION_TAB = [
    {
      value: '&is_archived=false',
      label: dictionary['operations'],
    },
    {
      value: '&is_archived=false&',
      type: "map",
      label: dictionary['map'],
    },
    {
      value: '&is_archived=true',
      label: dictionary['archived'],
    },
  ]

  const columns = [
    {
      field: 'name',
      headerName: dictionary['name'],
      renderCell: (params) => (
        <>
          {!isLoading &&
            <>
              {params.row.photo ? (
                <img className={classes.picture} src={params.row.photo} />
              ) : (
                <Skeleton
                  className={classes.picture}
                  variant="rect"
                  width={48}
                  height={48}
                />
              )}
              <Link href={`/operations/${params.row.id}`}>{params.row.name}</Link>
            </>
          }
        </>
      ),
      flex: 1,
    },
    {
      field: 'city',
      headerName: dictionary['city'],
      renderCell: CellExpand,
      minWidth: 150,
    },
    // {
    //   field: 'type_display',
    //   headerName: dictionary['type'],
    //   minWidth: 120,
    // },
    {
      field: 'surface_area_manual',
      headerName: dictionary['surface'],
      type: 'number',
      renderCell: (params) => (
        <div className={classes.numeric}>
          {numberWithSpaces(params.row.surface_area_manual)} {formatUnit(params.row.unit)}
        </div>
      ),
      minWidth: 150,
    },
    {
      field: 'potential_rent',
      headerName: dictionary['potential_rent'],
      type: 'number',
      minWidth: 170,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'actual_rent',
      headerName: dictionary['rent'],
      type: 'number',
      minWidth: 170,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'occupancy_rate',
      headerName: dictionary['occupancy'],
      type: 'number',
      minWidth: 150,
      valueFormatter: (params) => `${numberWithSpaces(params.value)}%`
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
      width: 24,
      cellClassName: 'actions_cell',
      // flex: 1,
    },
  ]

  if (isError) return <Page404View />
  return (
    <Layout title={dictionary['operations']}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h3" gutterBottom>
                {dictionary['operations']}
              </Typography>
            </Paper>
          </Grid>
          <div className={classes.paperCard}>
            <Grid container className={classes.cardContainer} spacing={3}>
              <IndicatorCard data={data && data.count} title={dictionary['operations']} total={5} />
              <IndicatorCard calc={data && data.results} unit={'m2'} calcKey='surface_area_manual' title={dictionary['surface']} />
              <IndicatorCard calc={data && data.results} unit={'€'} calcKey='potential_rent' title={dictionary['potential_rent']} />
              <IndicatorCard calc={data && data.results} unit={'€'} calcKey='actual_rent' title={dictionary['rent']} />
            </Grid>
          </div>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <TabContext value={value}>
                <div className={classes.tableNav}>
                  <TabList
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    className={classes.tabList}
                    onChange={handleChange}
                  >
                    {OPERATION_TAB.map((tab) => (
                      <Tab className={classes.tab} key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                  </TabList>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    className={classes.submit}
                    onClick={handleFormOpen}
                  >
                    {!isMobile && dictionary['add_operation']}
                  </Button>
                </div>
              </TabContext>
              {isMap ?
                <MapView center={{ lat: 48.8685708, lng: 2.3302606 }}
                />
                :
                <Table
                  rows={data && data.results ? data.results : []}
                  columns={columns}
                  page={pageCount}
                  handlePageChange={handlePageChange}
                  count={data && data.count}
                  loading={isLoading}
                  components={{
                    Footer: FooterRow
                  }}
                />
              }

            </Paper>
          </Grid>
          {(isOpenForm || isEdit) && (
            <ModalForm type={dictionary['operation']}
              onClose={isEdit ? handleEditClose : handleFormClose}
              isEdit={isEdit}
            >
              <OperationForm
                fetchUrl={fetchUrl}
                isEdit={isEdit}
                isList
                handleClose={isEdit ? handleEditClose : handleFormClose}
                id={id}
              />
            </ModalForm>
          )}
          {isDelete && (
            <ModalDelete
              onClose={handleDeleteClose}
              onDelete={deleteOperation}
            />
          )}
          {isArchive && (
            <ModalDelete
              onClose={handleArchiveClose}
              onDelete={archiveOperation}
              isYellow
              description={dictionary['confirm_archive']}
            />
          )}
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
