import React from 'react'
import Link from 'next/link'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import {
  Table,
} from '@components/common'
import { Loader } from '@components/common'
import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import Page404View from 'pages/404'

const useStyles = makeStyles((theme) => ({
  picture: {
    width: 48,
    height: 48,
    marginRight: '20px'
  },
  numeric: {
    width: '100%',
    textAlign: 'right',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function OperationTable() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { data, isLoading, isError } = useFetchData('/operations/')

  const formatUnit = (unit) => {
    if (unit === 'm') {
      return 'm2'
    } else {
      return 'ft2'
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: dictionary["name"],
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.photo ? (<img className={classes.picture} src={params.row.photo} />) : <></>}
          <Link href={`/operations/${params.row.id}`}>{params.row.display}</Link>
        </>
      ),
    },
    {
      field: 'surface_area_manual',
      headerName: dictionary["surface_area"],
      type: 'number',
      flex: 1,
      renderCell: (params) => (
        <div className={classes.numeric}>
          {params.row.surface_area_manual} {formatUnit(params.row.unit)}
        </div>
      ),
    }
  ]
  if (isLoading) return <Loader />
  if (isError) return <Page404View />
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Table
          rows={data && data.results ? data.results : []}
          columns={columns}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  )
}
