import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useGridSlotComponentProps } from '@material-ui/data-grid'
import Pagination from '@material-ui/lab/Pagination'

import { useTranslation } from '@contexts/translation'
import { numberWithSpaces } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function FooterRow() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { state, apiRef, columns, rows } = useGridSlotComponentProps()
  const totalRow = () => {
    if (rows?.length > 0) {
      const forceReduce = rows.push({
        name: '',
        city: null,
        surface_area_manual: 0,
        potential_rent: 0,
        actual_rent: 0
      })
      const totalRow = rows?.reduce((acc, value) => {
        return {
          name: dictionary['total'],
          category: null,
          surface_area_manual: acc.surface_area_manual + value.surface_area_manual,
          potential_rent: acc.potential_rent + value.potential_rent,
          actual_rent: acc.actual_rent + value.actual_rent
        }
      })
      return [totalRow]
    }
  }

  const itemStyle = {
    width: columns[1].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }
  // const itemStyleType = {
  //   width: columns[2].computedWidth,
  //   padding: '0 10px',
  //   fontWeight: 'bold',
  //   textAlign: 'right',
  // }
  const itemStyleSurface = {
    width: columns[2].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  const itemStylePotentialRent = {
    width: columns[3].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }
  const itemStyleRent = {
    width: columns[4].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }
  const itemStyleName = {
    width: columns[0].computedWidth,
    padding: '0',
    fontWeight: 'bold',
  }

  return (
    <div className={`${classes.root} MuiDataGrid-footer`}>
      {rows?.length > 0 && columns.length > 2 && !state.options.loading && (
        <div>
          {totalRow().map((item, index) => (
            <div className={classes.list} key={`${item?.name}-${index}`}>
              <div style={itemStyleName}>
                {state.pagination.pageCount > 1 && (
                  <Pagination
                    color="primary"
                    count={state.pagination.pageCount}
                    page={state.pagination.page + 1}
                    size="small"
                    siblingCount={0}
                    onChange={(event, value) => value && apiRef.current.setPage(value - 1)} />
                )}
              </div>
              <div style={itemStyle}>
                {item?.city}
              </div>
            
              <div style={itemStyleSurface}>
                {numberWithSpaces(item?.surface_area_manual)} m2
              </div>
              <div style={itemStylePotentialRent}>
                {numberWithSpaces(item?.potential_rent)}
              </div>
              <div style={itemStyleRent}>
                {numberWithSpaces(item?.actual_rent)}
              </div>
              <div></div>
            </div>
            
          ))}
        </div>
      )}
    </div>
  )
}
