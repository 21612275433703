import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {
  List,
  Card,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core'


import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useFetchData } from '@api/fetchData'

import { Layout, StickyHeader } from '@components/common'
import OperationForm from '@components/operations/OperationForm'
import Page404View from 'pages/404'

import { useOperation } from '@contexts/operation'
import { useTranslation } from '@contexts/translation'
import useBreakpoints from '@hooks/useBreakpoints'
import UsersTable from './UsersTable'
import { OperationSettingsForm } from '.'
import { BudgetsTableCategories } from '@components/budgets'
import { LandlordsTable } from '@components/landlords'
import MandatesTable from '@components/mandates/MandatesTable'
import { useRouter } from "next/router";
import Import from "@components/profile/Import";


const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    maxHeight: 'calc(100vh - 64px)',
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, .8)',
    textTransform: 'uppercase',
  },
  root: {
    flexGrow: 1,
  },
  formTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabNav: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  sticky: {
    position: 'sticky',
    zIndex: '999',
    top: '40px'
  }
}))

export default function SettingsView({ id }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [selectedId, setId] = useState(1)
  const router = useRouter();
  const [dataOperation, setDataOperation] = useState({})
  const isMobile = useBreakpoints('down', 'sm')

  const MENU = [
    {
      id: 1,
      name: dictionary["info"],
      onClick: () => setId(1),
      isExpand: false,
      disabled: false,
    },
    {
      id: 2,
      name: dictionary['users'],
      onClick: () => setId(2),
      isExpand: false,
      disabled: false,
    },
    {
      id: 3,
      name: dictionary['invoice_settings'],
      onClick: () => setId(3),
      isExpand: false,
      disabled: false,
    },
    {
      id: 4,
      name: dictionary['categories_expenses'],
      onClick: () => setId(4),
      isExpand: false,
      disabled: false,
    },
    // {
    //   id: 5,
    //   name: dictionary.landlords.landlords,
    //   onClick: () => setId(5),
    //   isExpand: false,
    //   disabled: false,
    // },
    {
      id: 6,
      name: dictionary.mandates.mandates,
      onClick: () => setId(6),
      isExpand: false,
      disabled: false,
    },
    {
      id: 7,
      name: dictionary['import'],
      onClick: () => setId(7),
      isExpand: false,
      disabled: false,
    },
  ]

  const { operation, updateBreadcrumb } = useOperation()

  const fetchUrl = `/operations/${id}/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)


  useEffect(() => {
    if (data) {
      updateBreadcrumb()
      setDataOperation(data)
    }
  }, [data])


  useEffect(() => {
    if (router.query && router.query.info) {
      setId(parseInt(router.query.info))
    }
  }, [router])
  if (isError) return <Page404View />

  return (
    <Layout isSidebar operationId={id}>
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          {data && (
            <Grid container spacing={3}>
              <StickyHeader
                operationName={operation?.display}
                breadcrumb={[
                  { name: dictionary["operations"], href: `/operations/` },
                  { name: operation?.name, href: `/operations/${id}` },
                  { name: dictionary["settings"] }
                ]} />
              {!isMobile && (
                <Grid item xs={3}>
                  <Paper className={classes.paper}>
                    <Card>
                      {MENU && (
                        <List
                          component="nav"
                          aria-labelledby=""
                          className={classes.tabNav}
                        >
                          {MENU.map((item, index) => (
                            <>
                              <ListItem
                                button
                                key={index}
                                onClick={item?.onClick}
                                disabled={item?.disabled}
                              >
                                <ListItemText primary={item?.name} />
                                {item?.isExpand && (
                                  <>{open ? <ExpandLess /> : <ExpandMore />}</>
                                )}
                              </ListItem>
                              {item?.expandItem && (
                                <Collapse
                                  in={open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    {item?.expandItem?.map((item, index) => (
                                      <ListItem
                                        button
                                        className={classes.nested}
                                        key={index}
                                      >
                                        <ListItemText primary={item?.name} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Collapse>
                              )}
                            </>
                          ))}
                        </List>
                      )}
                    </Card>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={isMobile ? 12 : 9}>
                <Paper className={classes.paper}>
                  {selectedId === 1 && <Card>
                    {data && (
                      <div className={`${classes.root}`}>
                        <div className={classes.formTitleContainer}>
                          <p>
                            {dictionary['edit_operations']}{' '}
                            {dataOperation?.name}
                          </p>
                        </div>
                        <OperationForm
                          isEdit={true}
                          id={id}
                          fetchUrl={fetchUrl}
                          formData={data}
                        />
                      </div>
                    )}
                  </Card>
                  }
                  {selectedId === 2 &&
                    <UsersTable
                      id={id}
                      isSettings={true}
                    />}
                  {selectedId === 3 &&
                    <Card>
                      <OperationSettingsForm
                        id={id}
                        fetchUrl={fetchUrl}
                        formData={data}
                      />
                    </Card>

                  }
                  {selectedId === 4 &&
                    <BudgetsTableCategories operationId={id} />
                  }
                  {selectedId === 5 &&
                    <LandlordsTable operationId={id} />
                  }
                  {selectedId === 6 &&
                    <MandatesTable operationId={id} />
                  }
                  {selectedId === 7 &&
                    <Import operationId={id} />
                  }
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </main>
    </Layout>
  )
}
