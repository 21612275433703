import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@components/common'
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, Card, Typography, Grid } from '@material-ui/core';
import { numberWithSpaces, numberWithSpaceInt } from '@utils/numberWithSpaces'


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    maxHeight: 120
  },
  trending: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  trendingIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.16)
  },
  numberDisplay: {
    textAlign: 'right'
  },
  isTrendingDown: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.16)
  },
}));

// ----------------------------------------------------------------------

MetricCardWithLine.propTypes = {
  className: PropTypes.string
};

function MetricCardWithLine({ hasMargins, isLoading, calc, data, calcKey, className, title, unit, icon = null, ...other }) {
  const classes = useStyles();
  const styles = hasMargins ? {
    marginRight: 1
  } : {};

  const total = (data) => {
    if (calcKey === "surface_area_manual") {
      return numberWithSpaces(data.reduce((sum, cur) => sum + cur[calcKey], 0))
    }
    else {
      return numberWithSpaceInt(data.reduce((sum, cur) => sum + cur[calcKey], 0))
    }
  }

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card className={clsx(classes.root, className)} {...other} sx={styles}>
        {isLoading ? <Loader /> : (<><Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ whiteSpace: 'nowrap' }}>
            {title}
          </Typography>
          <Typography className={classes.numberDisplay} variant="h3" gutterBottom>
            {data ? data : calc && calc.length > 0 ? total(calc) : 0}  {unit}
          </Typography>
        </Box>
          {icon}
        </>)}
      </Card>
    </Grid>
  );
}

export default MetricCardWithLine;
